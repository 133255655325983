import * as React from 'react'
import BasePage from '../../components/basePage'
import { Button, Flex, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { sendEmailLink } from '../../services/auth'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import { useState } from 'react'
import {
	getBookingId,
	getEventId,
	getRedirectUrl,
	setEmail
} from '../../helpers/database'
import Input from '../../components/input'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import eventTracking from '../../services/eventTracking'

const LinkNotValidPage = () => {
	const [isLoading, setLoading] = useState(false)

	const resolver = yupResolver(
		object()
			.shape({
				email: string().trim().email().required()
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})
	const watchEmail = watch('email')
	const isValid = !errors?.email && !!watchEmail

	const onSubmit = async (data) => {
		if (!data?.email || !!errors?.email) return
		const email = data.email.toLowerCase().trim() //.replace(/\+/g, '')
		setLoading(true)
		const bookingId = getBookingId()
		const eventId = getEventId()
		const redirectUrl = getRedirectUrl()
		const link = `${window?.location?.origin
			}/login/finish-registration?email=${email}${!!bookingId ? `&bookingId=${bookingId}` : ''
			}${!!eventId ? `&eventId=${eventId}` : ''}${!!redirectUrl && !bookingId && !eventId
				? `&redirect=${redirectUrl}`
				: ''
			}`
		await sendEmailLink(email, link)
		setEmail(email)
		setLoading(false)

		eventTracking("resend_link", {
			"email": email
		})
		await navigate('/login/verify-email/')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				access={0}
				theme="dark"
				parent="login"
				backBtn={'/'}
				largeTitle={
					<>
						Link Not
						<br />
						Valid
					</>
				}
			>
				<Text variant="m" color="white" textAlign="center" mt="40px">
					Uh oh! This link isn’t valid. Try sending a <br />
					new link to sign in again.
				</Text>
				<Input
					name="email"
					id="email"
					type="email"
					variant="filled"
					placeholder="Your email"
					mt="40px"
					register={register}
					errors={errors}
				/>
				<Flex>
					<Button
						variant="primary"
						mt="40px"
						disabled={!isValid || isLoading}
						isLoading={isLoading}
						type="submit"
					>
						Resend Link
					</Button>
				</Flex>
				<StaticImage
					style={{
						height: 198,
						width: 181,
						position: 'absolute',
						bottom: 0,
						right: 0
					}}
					alt="Duck"
					src="../../images/duck-lg.png"
				/>
			</BasePage>
		</form>
	)
}

export default LinkNotValidPage
