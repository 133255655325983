import * as React from 'react'
import Layout from '../../layout'
import LinkNotValidPage from '../../pagesComponents/login/not-valid'

const NotValid = () => {
	return (
		<Layout title="Link not valid">
			<LinkNotValidPage />
		</Layout>
	)
}

export default NotValid
